<template>
  <el-card shadow="never" class="report-card" v-loading="loading">
    <div slot="header" class="report-card-header">
      <div class="title">
        <span>{{ title }}</span>
      </div>
      <div class="condition">
        <div class="condition-item" v-for="item in conditionData" :key="item.value"
             :class="{'condition-item-active': currentDateType === item.value}"
             @click="handleDateChange(item.value)"><span>{{ item.label }}</span></div>
      </div>
    </div>
    <div class="report-card-body">
      <el-row :gutter="10">
        <el-col :span="20">
          <div id="salesChart"></div>
        </el-col>
        <el-col :span="4">
          <div class="rank">
            <div class="rank-title">业绩排名</div>
            <div class="rank-list">
              <div class="rank-item" v-for="(item, index) in salesRankData" :key="item.id">
                <div class="rank-item-index" :class="{'rank-item-index-active': index < 3}">{{ index + 1 }}</div>
                <div class="rank-item-name">{{ item.displayName }}</div>
                <div class="rank-item-money">{{ item.totalAmount }}</div>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </el-card>
</template>
<script>
import { RptApi } from '@/api'
import * as echarts from 'echarts'

export default {
  name: 'SalesResultCard',
  props: {
    dataType: String,
    title: String
  },
  data () {
    return {
      conditionData: [
        {
          label: '今日',
          value: 1
        },
        {
          label: '本周',
          value: 2
        },
        {
          label: '本月',
          value: 3
        },
        {
          label: '本年',
          value: 4
        }
      ],
      currentDateType: 1,
      loading: false,
      salesRankData: [],
      chartData: []
    }
  },
  methods: {
    handleDateChange (value) {
      this.currentDateType = value
      this.getData()
    },
    getData () {
      this.getChartData()
      this.getSalesRankData()
    },
    getSalesRankData () {
      RptApi.getEmployeeRank({
        dateType: this.currentDateType,
        page: 0,
        size: 10
      }).then(res => {
        this.salesRankData = res.rows
      })
    },
    getChartData () {
      this.loading = true
      const params = {
        dateType: this.currentDateType
      }
      if (this.dataType === 'user') {
        params.userId = this.$store.getters.userId
      }
      if (this.dataType === 'org') {
        params.orgId = this.$store.getters.orgId
      }
      RptApi.getReportChartData(params).then(res => {
        const data = res.map(it => [it.label, Number(it.totalAmount), Number(it.total), Number(it.totalV1), Number(it.totalV3)])
        this.myChart.clear()
        this.myChart.setOption({
          legend: {},
          tooltip: {},
          formatter: function (params) {
            // ['周四', 1800, 1, 1, 0]
            const value = params.value
            let html = ''
            if (params.value) {
              html = `
              ${value[0]}: <br/>
              销售额：${value[1]} <br/>
              VIP总数：${value[2]} <br/>
              VIP(一年)：${value[3]} <br/>
              VIP(三年)：${value[4]} <br/>
              `
            }
            return html
          },
          dataset: {
            // 提供一份数据。
            source: data
          },
          // 声明一个 X 轴，类目轴（category）。默认情况下，类目轴对应到 dataset 第一列。
          xAxis: { type: 'category' },
          // 声明一个 Y 轴，数值轴。
          yAxis: {},
          // 声明多个 bar 系列，默认情况下，每个系列会自动对应到 dataset 的每一列。
          series: [{ type: 'bar' }]
        })
        this.loading = false
      })
    }
  },
  mounted () {
    this.myChart = echarts.init(document.getElementById('salesChart'))
  },
  created () {
    this.getData()
  }
}
</script>

<style scoped lang="less">
.report-card-header {
  display: flex;
  align-items: center;
  justify-content: space-around;

  .title {
    flex: 1;
  }

  .condition {
    display: flex;
    align-items: center;

    .condition-item {
      padding: 0 10px;
      font-size: 14px;

      span {
        cursor: pointer;
      }

      &:hover {
        color: #fdac1e;
      }
    }

    .condition-item-active {
      color: #fdac1e;
    }
  }
}

.report-card-body {
  .rank {
    //padding: 0 32px 32px 72px;

    .rank-title {
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 10px;
    }

    .rank-list {
      .rank-item {
        display: flex;
        align-items: center;
        font-size: 14px;
        margin-top: 16px;

        .rank-item-index {
          display: inline-block;
          width: 20px;
          height: 20px;
          margin-top: 1.5px;
          margin-right: 16px;
          font-weight: 600;
          font-size: 12px;
          line-height: 20px;
          text-align: center;
          background-color: #fafafa;
          border-radius: 20px;
        }

        .rank-item-index-active {
          color: #fff;
          background-color: #fdac1e;
        }

        .rank-item-name {
          flex: 1;
        }

        .rank-item-money {

        }
      }
    }
  }
}

#salesChart {
  width: 100%;
  height: 400px;
}
</style>
